import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { mediaQuery } from 'theme/kantan';

import {
  BaseCalendarView,
  SubmitOptions,
} from 'components/redesign/CalendarView/BaseCalendarView';
import { DEFAULT_CURRENCY } from 'layouts/MarketingLayout/MarketingLayout';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { StepOptions } from 'services/kantanClient';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { JOB_TYPES, mapJobTypeToProductType } from 'utils/jobTypes';
import {
  checkSelectedTimeIsEco,
  getPriceFromJobType,
  grabGclid,
  trackEvent,
} from 'utils/tracking';
import { shouldForwardProp } from 'utils/transientStyled';
import { useManagedRates } from 'utils/useManagedRates';
import { useServiceAvailability as useServiceStyleAvailability } from 'utils/useServiceAvailability';

export const SelectTime = (stepConfigOptions: StepOptions) => {
  const context = useExistingStepContext();
  const energyExpertStore = useEnergyExpertsStore((state) => ({
    timeSlots: state.timeSlots,
    setTimeSlots: (timeSlots: string[]) => state.setState({ timeSlots }),
  }));
  const genericStore = useGenericStore((state) => ({
    timeSlots: state.timeSlots,
    setTimeSlots: (timeSlots: string[]) => state.setState({ timeSlots }),
  }));
  const postcode = useAddressStore((state) => state.postcode);

  const {
    numberOfWeeksAvailabilityToShow: numberOfWeeksToShow,
    leadTimeDays,
    fallbackSlotThreshold,
    id: productId,
    fallbackEnabled,
    temporaryJobType: jobType = JOB_TYPES.HOME_ENERGY_ASSESSMENT,
    useGenericStoreData: shouldUseGenericStoreData,
  } = context.productConfig;

  const { timeSlots, setTimeSlots } = shouldUseGenericStoreData
    ? genericStore
    : energyExpertStore;

  const availability = useServiceStyleAvailability({
    postcode,
    isConsolidatedAvailabilityEnabled: true,
    isAvailabilityFallbackEnabled: fallbackEnabled,
    numberOfWeeksToShow,
    leadTimeDays,
    jobType,
    isBulkSlotRatingsEnabled: stepConfigOptions.isBulkSlotRatingsEnabled,
    fallbackSlotThreshold,
    productId,
  });

  const router = useRouter();
  const managedRates = useManagedRates();

  const handleSubmit = ({ sortedBookableSlots }: SubmitOptions) => {
    trackEvent('Appointment details submitted', {
      product_type: mapJobTypeToProductType(jobType),
      job_type: jobType,
      price: getPriceFromJobType(jobType, managedRates).amount,
      currency: DEFAULT_CURRENCY,
      slots_shown: sortedBookableSlots
        .filter((slot) => slot.isAvailable)
        .map((slot) => ({
          ...slot,
          startDateTime: slot.startDateTime.toISOString(),
          endDateTime: slot.endDateTime.toISOString(),
        })),
      is_fallback: availability.isFallback,
      count_of_slots_selected: 1,
      slots_chosen: timeSlots,
      eco_slot_selected:
        checkSelectedTimeIsEco(sortedBookableSlots, timeSlots) ?? false,
      gclid: grabGclid(router),
    });
    void router.push({
      pathname: stepConfigOptions.nextStep,
    });
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <BaseCalendarView
        availability={availability}
        timeSlots={timeSlots}
        onTimeSlotsChange={setTimeSlots}
        onSubmit={handleSubmit}
      />
    </OuterContainer>
  );
};

const OuterContainer = styled(m.section, {
  shouldForwardProp,
})`
  grid-column: 1 / -1;
  ${({ theme }) => `margin: 105px -${theme.app.margin.mediumLargeMargin} 65px;`}

  @media (${mediaQuery('tablet')}) {
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(20px);
    border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    ${({ theme }) =>
      `margin: ${theme.app.margin.mediumLargeMargin} -${theme.app.margin.mediumLargeMargin} 0;`}
    justify-self: center;
    height: max-content;
    padding-bottom: ${({ theme }) => theme.app.padding.extraLargePadding};
    overflow: hidden;
  }

  @media (${mediaQuery('desktop')}) {
    max-width: 990px;
  }

  button {
    height: 44px;
  }
`;
