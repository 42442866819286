import { addBreadcrumb } from '@sentry/browser';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { Icon } from 'utils/formRadioOptions';
import { JOB_TYPES, PRODUCT_NAME } from 'utils/jobTypes';
import { Maybe } from 'utils/types';
import { AppointmentData } from 'utils/usePersistedState';

axios.defaults.xsrfCookieName = 'csrftoken';
const axiosWithAuth = axios.create({ withCredentials: true });
const axiosForAvailabilities = axios.create();

export const configAxiosForAvailabilities = (config: { tpPayload: string }) => {
  const gatewayURI = process.env.NEXT_PUBLIC_KANTAN_GATEWAY_URI;
  axiosForAvailabilities.defaults.baseURL = gatewayURI;
  axiosForAvailabilities.defaults.headers.common['X-Shared-Calendar-Secret'] =
    config.tpPayload;
};

///////////////////////
// AUTH
///////////////////////

const getTokens = (): {
  accessToken: string | null;
  refreshToken: string | null;
} => {
  // Ensure localStorage permission errors don't interrupt flow.
  try {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    return {
      accessToken,
      refreshToken,
    };
  } catch (error) {
    addBreadcrumb({
      message: 'Could not read tokens from localStorage',
      data: { error },
    });
    return {
      accessToken: null,
      refreshToken: null,
    };
  }
};

const clearTokens = () => {
  // Ensure localStorage permission errors don't interrupt flow.
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } catch (error) {
    addBreadcrumb({
      message: 'Could not clear tokens from localStorage',
      data: { error },
    });
  }
};

export type AuthResponse = {
  accessToken: string;
  refreshToken: string;
};

export const authenticate = async (
  loginToken: string,
): Promise<AuthResponse> => {
  const response = await axios.post<AuthResponse>(
    `/api/contact/v1/auth`,
    { loginToken },
    { withCredentials: true },
  );

  // Remove all tokens from storage; rely on cookies for future requests.
  clearTokens();

  return response.data;
};

export const refreshTokens = async (): Promise<AuthResponse> => {
  // Use refreshToken from storage, if present.
  const { refreshToken } = getTokens();
  const body = typeof refreshToken === 'string' ? { refreshToken } : {};

  // Successful response will automatically set access/refresh token cookies.
  const response = await axios.post<AuthResponse>(
    '/api/contact/v1/refresh-token',
    body,
    { withCredentials: true },
  );

  // Remove all tokens from storage; rely on cookies for future requests.
  clearTokens();

  return response.data;
};

const refreshWithLogging = (error: Error): Promise<AuthResponse> => {
  addBreadcrumb({
    message: 'Refreshing token in response to error',
    data: { error },
  });
  return refreshTokens();
};

createAuthRefreshInterceptor(axiosWithAuth, refreshWithLogging, {
  pauseInstanceWhileRefreshing: false,
});

///////////////////////
// CONTACT JOBS
///////////////////////

export interface ContactJob {
  fulfillmentWindow: {
    startDateTime: string | null;
    endDateTime: string | null;
  };
  preferredSlots: Array<{
    startDatetime: string;
    endDatetime: string;
  }>;
  jobType: string;
  jobTypeGroup: string;
  metadata: Record<string, string>;
  address: {
    line1: string;
    line2?: string;
    postcode: string;
    town: string;
  };
  contacts: Array<{
    fullName: string;
    emailAddress: string;
    phoneNumbers: Array<{ phoneNumber: string }>;
  }>;
  tradespersonAssigned: boolean;
  appointmentId: string;
  referenceId: string;
  productName: PRODUCT_NAME;
  productId?: string;
}

export const getContactJob = async (id: string): Promise<ContactJob> => {
  const response = await axiosWithAuth.get<ContactJob>(
    `/api/contact/v1/partner-jobs/${id}`,
  );
  return response.data;
};

export interface UpdateJobArgs {
  id: string;
  input: {
    fulfillmentWindow: {
      startDateTime: string | null;
      endDateTime: string | null;
    };
    preferredSlots: Array<{ startDatetime: string; endDatetime: string }>;
  };
}

export const updateContactJob = async (
  args: UpdateJobArgs,
): Promise<ContactJob> => {
  const response = await axiosWithAuth.put<ContactJob>(
    `/api/contact/v1/partner-jobs/${args.id}`,
    args.input,
    { withCredentials: true },
  );
  return response.data;
};

export interface CancelJobArgs {
  id: string;
  input: {
    reason: string;
  };
}

export const cancelContactJob = async (
  args: CancelJobArgs,
): Promise<ContactJob> => {
  const response = await axiosWithAuth.post<ContactJob>(
    `/api/contact/v1/partner-jobs/${args.id}/cancel`,
    args.input,
    { withCredentials: true },
  );
  return response.data;
};

///////////////////////
// APPOINTMENTS
///////////////////////

type SlotShape = {
  startTime: string; // e.g. '08:00:00';
  endTime: string; // e.g. '12:00:00';
  queryStartTime: string; // e.g. '00:00:00';
  queryEndTime: string; // e.g. '12:00:00';
  name: string; // e.g. 'Morning';
};

export type AppointmentResponse = {
  id: string;
  fulfillmentWindow: {
    startDateTime: string | null;
    endDateTime: string | null;
  };
  timezone: string;
  contactId: string;
  tradespersonId: string;
  jobId: string;
  createdAt: string;
  updatedAt: string;
  canBeAutobooked: boolean;
  setForAutobooking: boolean;
  jobType: string;
  dailySlotShape: SlotShape[];
};

export const fetchAppointment = async (
  id: string,
): Promise<AppointmentResponse> => {
  const response = await axiosWithAuth.get<AppointmentResponse>(
    `/api/contact/v1/appointments/${id}`,
  );
  return response.data;
};

export const bookAppointment = async (
  appointmentId: string,
  startDateTime: Date,
  endDateTime: Date,
): Promise<AppointmentResponse> => {
  const response = await axiosWithAuth.put<AppointmentResponse>(
    `/api/contact/v1/appointments/${appointmentId}`,
    {
      fulfillmentWindow: {
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
      },
    },
  );
  return response.data;
};

export type BookableSlotResponse = {
  startDateTime: string;
  endDateTime: string;
  ratingQueryStartDateTime: string;
  ratingQueryEndDateTime: string;
  isAvailable: boolean;
  isFullyBooked: boolean;
  appointments: string[];
  actualSlots: Array<{ startDateTime: string; endDateTime: string }>;
};

export const fetchBookableSlots = async (
  appointmentId: string,
  startDateTime: Date,
  endDateTime: Date,
): Promise<BookableSlotResponse[]> => {
  const response = await axiosWithAuth.get<BookableSlotResponse[]>(
    `/api/contact/v1/appointments/${appointmentId}/bookable-slots`,
    {
      params: {
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
      },
    },
  );
  return response.data;
};

export type SlotRatingResponse = {
  score: number;
};

export const fetchSlotRating = async (
  appointmentId: string,
  startDateTime: Date,
  endDateTime: Date,
): Promise<SlotRatingResponse> => {
  const slotId = encodeURIComponent(
    `${startDateTime.toISOString()}|${endDateTime.toISOString()}`,
  );
  const response = await axiosWithAuth.get<SlotRatingResponse>(
    `/api/contact/v1/appointments/${appointmentId}/rating/${slotId}`,
  );
  return response.data;
};

export const createPrivateBookingAppointment = async (
  data: AppointmentData,
) => {
  return Promise.resolve();
  // const response = await axiosWithAuth.post<AppointmentResponse>(
  //   `/api/contact/v1/appointments`,
  //   data,
  // );
  //
  // return response.data;
};

///////////////////////
// CONTACT AVAILABILITY
///////////////////////

export type SetContactAvailabilityResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  startDateTime: string;
  endDateTime: string;
};

export const setContactAvailability = async (
  startDateTime: Date,
  endDateTime: Date,
): Promise<SetContactAvailabilityResponse> => {
  const response = await axiosWithAuth.post(`/api/contact/v1/availabilities`, {
    startDateTime: startDateTime.toISOString(),
    endDateTime: endDateTime.toISOString(),
  });
  return response.data;
};

export const fetchPrivateBookableSlots = async (
  startDateTime: string,
  endDateTime: string,
) => {
  const response = await axiosForAvailabilities.get<BookableSlotResponse[]>(
    `api/contact/v1/contact-tradesperson-availabilities/bookable-slots`,
    {
      params: {
        startDateTime,
        endDateTime,
      },
    },
  );

  return response.data;
};

///////////////////////
// CONFIGURATION
///////////////////////

export type ConfigurationResponse = {
  DAILY_SLOT_SHAPE: [];
  SLOT_RATING: {
    recommendedThreshold: number;
  };
};

export const fetchConfiguration = async (): Promise<ConfigurationResponse> => {
  const response = await axiosWithAuth.get<ConfigurationResponse>(
    `/api/contact/v1/configuration`,
  );
  return response.data;
};

export const fetchPrivateBookingConfiguration =
  async (): Promise<ConfigurationResponse> => {
    const response = await axiosForAvailabilities.get<ConfigurationResponse>(
      `api/contact/v1/contact-tradesperson-availabilities/configuration`,
    );
    return response.data;
  };

///////////////////////
// PRODUCTS
///////////////////////

interface GetProductConfigParams {
  url?: string;
  productId?: string;
  axiosBaseUrl?: string;
}

export interface Tracker {
  stepNumber?: number;
  renderType?: string;
  label: string;
  progressBarStart: number;
  progressBarEnd: number;
  isTrackerActive: boolean;
  subtitle?: string;
  mobileTitle?: string;
  mobileSubtitle?: string;
  mobileProgress: number;
  isLastStep: boolean;
  mobileStepHidden?: boolean;
}

interface RedirectIfMissingData {
  dataToCheck: string[];
}

export interface StepOptions {
  index: number;
  prevStep: string;
  nextStep: string;
  redirectIfMissingData?: RedirectIfMissingData;
  mobileBackgroundColor: string;
  tracker: Tracker;
  isBulkSlotRatingsEnabled?: boolean;
}

export type LogoComponentName = 'OvoLogo' | 'KantanLogo' | 'TadoLogo';

export interface LogoProps {
  color?: string;
  width?: string;
  height?: string;
}

export interface LogoOptions {
  component: LogoComponentName;
  props?: LogoProps;
  secondaryLogo?: {
    component: LogoComponentName;
    props?: LogoProps;
  };
}

export type StepComponentName =
  | 'Postcode'
  | 'NoAvailability'
  | 'NotInYourArea'
  | 'SignUpAcknowledge'
  | 'Motivation'
  | 'SelectTime'
  | 'SelectAddress'
  | 'ConfirmAddress'
  | 'ResidentDetails'
  | 'ReviewBooking'
  | 'BookingConfirmation'
  | 'PaymentConfirmation'
  | 'SelectBoilerType'
  | 'SelectFuelType'
  | 'IneligibleHomeSetup'
  | 'YourHome'
  | 'SignUpAcknowledgeTado'
  | 'NotInYourAreaTado'
  | 'ConfirmTadoPurchase';

interface Step {
  name: string;
  component: StepComponentName;
  options: StepOptions;
}

interface FlowOptions {
  pageTitle: string;
  trackerHeaderTitle?: string;
}

type Flows = {
  booking?: FlowOptions;
  reschedule?: FlowOptions;
  cancel?: FlowOptions;
};

export type MotivationOption = {
  value: string;
  icon?: Icon;
  label: string;
};

export type MotivationConfig = {
  includeTextInput: boolean;
  textInputLabel: string;
};

export type Motivations = {
  config?: MotivationConfig;
  options: MotivationOption[];
};

type FlowDisabledOptions = {
  isFlowDisabled: boolean;
  headText: string;
  bodyText: string;
};

type MetadataField = {
  name: string;
  label: string;
  required: boolean;
};

/**
 * Options present in both the hardcoded config (at the root)
 * and server config (nested in its "residentPortalOptions" field).
 */
export interface CommonNestedProductConfigOptions {
  totalNumberOfSteps: number;
  homePageUrl: string;
  landingPageUrl: string;
  routeToRedirectIfMissingData: string;
  numberOfWeeksAvailabilityToShow: number;
  bookingSummaryHeader: string;
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  showAgeLimitInfo: boolean;
  mobileViewBackgroundColor: string;
  mobileViewCalendarTileBackgroundColor: string;
  flows: Flows;
  logo: LogoOptions;
  steps: Step[];
}

// TODO: change to `string` or even `Maybe<string>` ideally
// not to rely on the job type group anywhere and use more granular
// and feature-driven config options instead
export type ProductJobTypeGroup = 'service';

/**
 * Options present in both the hardcoded and server configs.
 */
export interface CommonRootProductConfigOptions {
  jobTypeGroup: ProductJobTypeGroup;
  supportEmail: string;
  supportPhoneNumber: string;
  fallbackEnabled: boolean;
  fallbackSlotThreshold: number;
  leadTimeDays: number;
}

/**
 * Options present only in the server product config.
 */
export interface ServerOnlyRootProductConfigOptions {
  id: string;
  name: string;
  rescheduleCutoffHours: Maybe<number>;
  cancellationEnabled: Maybe<boolean>;
  selectedSlotsRequiredMinNonFallback: number;
  selectedSlotsRequiredMaxNonFallback: number;
  selectedSlotsRequiredMinOnFallback: number;
  selectedSlotsRequiredMaxOnFallback: number;
  motivations: Motivations | undefined;
  useGenericStoreData: boolean;
  temporaryJobType: JOB_TYPES;
  bookingSummaryDetailKeyName: string;
  discountLabel: Maybe<string>;
  termsAndConditionsText: string;
  infoAboveCalendarText: Maybe<string>;
  paymentType: string;
  flowDisabledOptions?: FlowDisabledOptions;
  metadataFields?: MetadataField[];
  postcodeAllowList: [];
  postcodeDenyList: [];
  customSupportingInfoText: Maybe<string>;
}

export type ServerProductOptions = CommonRootProductConfigOptions &
  ServerOnlyRootProductConfigOptions & {
    residentPortalOptions: CommonNestedProductConfigOptions;
  };

export interface ProductConfigResponse {
  product: Maybe<ServerProductOptions>;
  partner: {
    id: string;
    name: string;
    slug: string;
  };
}

export const fetchProductConfig = async ({
  productId,
  url,
  axiosBaseUrl = '',
}: GetProductConfigParams): Promise<ProductConfigResponse> => {
  const response = await axios.get<ProductConfigResponse>(
    `${axiosBaseUrl}/api/product-config`,
    { headers: { url }, params: { productId } },
  );
  return response.data;
};
